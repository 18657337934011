
.resource-pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: (92rem / 100) auto;
  margin-bottom: 0;
  .el-pagination {
    display: flex;
    align-items: center;
    margin-right: 0.2rem;

    .prevBtn,
    .nextBtn {
      width: (20rem / 100);
      height: (34rem / 100);
      cursor: pointer;
      img {
        width: 100%;
      }
    }

    .lastBtn {
      width: (60rem / 100);
      margin-left: (10rem / 100);
    }

    .currentPage {
      font-size: (16rem / 100);
      font-weight: normal;
    }
  }

  .el-pager li {
    font-size: 0.14rem;
    min-width: (40rem / 100);
    height: (40rem / 100);
    line-height: (40rem / 100);
    box-sizing: border-box;
    text-align: center;
  }

  /deep/ .el-pagination.is-background .el-pager li {
    width: (32rem / 100);
    height: (32rem / 100);
    line-height: (32rem / 100);
    border-radius: 50%;
    background-color: transparent;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev {
    margin: 0 5px;
    background-color: #f4f4f5;
    color: #32424f;
    min-width: 0.4rem;
    border-radius: 50%;
  }

  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #cd9e56 !important;
    color: #fff;
  }

  .jump-page-div {
    display: flex;
    align-items: center;
    font-size: (16rem / 100);
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #32424f;
    line-height: 0.2rem;

    .jump-page-div-input {
      width: (40rem / 100);
      height: (40rem / 100);
      background-color: transparent;
      border-radius: 0.05rem;
      border: 1px solid #8492c6;
      font-size: 0.2rem;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #32424f;
      line-height: 0.4rem;
      margin: 0 0.08rem;
      text-align: center;
    }

    .goPage {
      width: (40rem / 100);
      height: (40rem / 100);
      border: 1px solid #8492c6;
      border-radius: (5rem / 100);
      background-color: #fff;
      text-align: center;
      line-height: (40rem / 100);
      cursor: pointer;
    }
  }
}

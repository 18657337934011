// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以100；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}
.search {
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");
  padding-bottom: 0.92rem;
}
.search-container {
  @extend %inside-box;
  padding-top: 0.56rem;
  .search-result {
    color: #20252b;
    font-size: (20rem / 100);

    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    &-count {
      color: #8492c6;
    }
  }
  .search-bread {
    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    line-height: 0.2rem;
  }
}
// 头部
.search-header {
  width: 100%;
  height: (200rem / 100);
  background: url("https://image.bookgo.com.cn/webculture/jm/search/search-index-bg.png")
    no-repeat;
  background-size: 100% 100%;
  margin: (60rem / 100) 0 (40rem / 100);
  margin-top: 0.28rem;
  padding: 0 0.31rem;
  padding-top: 0.5rem;
  &-input {
    height: (60rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/search/search-index-border.png")
      no-repeat;
    background-size: 100% 100%;
    border-radius: (35rem / 100);
    padding: 0 0 0 (50rem / 100);
    margin-bottom: (34rem / 100);
    display: flex;
    align-items: center;
    position: relative;
    input {
      width: 87%;
      height: 100%;
      font-size: (20rem / 100);
      color: #20252b;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      background-color: transparent;
    }
    input::-webkit-input-placeholder {
      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #761f1e;
      line-height: 0.22rem;
    }
    &-select {
      width: 1.88rem;
      height: 100%;
      padding: 0.12rem 0;
      margin-right: 0.2rem;
      &-box {
        width: 100%;
        height: 100%;
        border-right: 1px solid #761f1e;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 0.16rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #761f1e;
          line-height: 0.35rem;
        }
        img {
          width: 0.16rem;
          height: 0.16rem;
        }
      }
      &-glassPic {
        width: 0.24rem;
        height: 0.24rem;
        margin-right: 0.1rem;
      }
    }
    &-button {
      float: right;
      width: (181rem / 100);
      height: 100%;
      line-height: (60rem / 100);
      background: url("https://image.bookgo.com.cn/webculture/jm/search/search-index-btn.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      cursor: pointer;
      font-size: (20rem / 100);
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
    }
    &-selection {
      width: 1.5rem;
      background-color: #f3ede0;
      border-radius: 0px 0px 10px 10px;
      border: 2px solid #761f1e;
      position: absolute;
      top: 0.6rem;
      border-top: none;
      padding-top: 0.04rem;
      padding-bottom: 0.08rem;
      z-index: 2;
      li {
        padding: 0.1rem 0;
        font-size: 0.16rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #273143;
        line-height: 0.22rem;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  &-select {
    .commonLi {
      //   width: (70rem /100);
      height: (40rem / 100);
      line-height: (40rem / 100);
      padding: 0 (15rem / 100);
      float: left;

      font-size: (20rem / 100);
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #20252b;
      cursor: pointer;
      margin-right: (30rem / 100);
    }
  }
  &-count {
    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    line-height: 0.2rem;
    .search-result-count {
      color: #761f1e;
      font-weight: 600;
    }
  }
}
// 内容主体
.search-result-main {
  margin-top: (20rem / 100);
  border: 1px solid #e8e8e8;
  position: relative;
  background-color: #fff;
  .border-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .border-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  &-noContent {
    width: 100%;
    height: 5.06rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-img {
      width: 2.18rem;
      height: 2.86rem;
      margin-bottom: 0.24rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #273143;
      line-height: 0.16rem;
    }
  }
  ul {
    li {
      height: (76rem / 100);
      line-height: (80rem / 100);
      font-size: (16rem / 100);
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #20252b;
      margin: (20rem / 100);
      margin-right: (30rem / 100);
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 (30rem / 100);
      border-bottom: 1px solid #d8d8d8;
      cursor: pointer;
      position: relative;
      overflow: unset;
      //   .line {
      //     width: 100%;
      //     height: 1px;
      //     background-color: #d8d8d8;
      //   }
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:hover {
        color: #cd9e56;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

// 激活的样式
.activeSearch {
  font-weight: 600 !important;
  color: #761f1e !important;
}

// 搜索高亮
.highlights-text {
  color: #761f1e;
  font-weight: 600;
}
